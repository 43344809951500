/* for addressMap component to remove close icon and arrow icon from InfoWindow component */
button.gm-ui-hover-effect {
  display: none !important;
}

.gm-style-iw-tc {
  display: none !important;
}

.gm-style-iw-t {
  bottom: 30px !important;
}
